/*卦象相关
i:get || post
h:requestGet ||requestPost
*/
import axios from '../axios'
export default {
  getDivinationApi: (data) => {
      return axios.get('/products/hexagram/get',data);
  },
  // 获取农历时间
  getNongLiApi: (data) => {
    return axios.get('/products/hexagram/gre2lun', data);
  },
  getDivinationByIdApi: (data) => {
    return axios.get('/products/hexagram/show', data);
  },
  upDateDivApi: (id, data) => {
    return axios.post('/products/hexagram/upsert/' + id, data);
  },
  // 历史记录列表
  getDivinationListApi: (data) => {
    return axios.get('/products/hexagram/index',data);
  },
  // 删除记录
  deleteListDivApi: (data) => {
    return axios.post('/products/hexagram/delete', data);
  },
}
/* 押金相关 */
export default [
    {
        path: 'spaceDisk/index',
        name: 'spaceDiskIndex',
        meta: {
            title: '空间盘',
        },
        component: () =>
            import ('@/views/spaceDisk/index.vue')
    },
    {
      path: 'spaceDisk/showImg',
      name: 'spaceDiskShowImg',
      meta: {
          title: '导入户型图',
      },
      component: () =>
          import ('@/views/spaceDisk/showImg.vue')
    },
    {
      path: 'spaceDisk/test',
      name: 'spaceDiskTest',
      meta: {
          title: '测试',
      },
      component: () =>
          import ('@/views/spaceDisk/test.vue')
    },
]
/* 卦象相关 */
/* keepAlive:该页面是否缓存 */
export default [
  {
      path: 'divination/list',
      name: 'divinationList',
      meta: {
          title: '卦象',
      },
      component: () => import('@/views/divination/list.vue')
  },
  {
      path: 'divination/timeDivination',
      name: 'timeDivination',
      meta: {
          title: '时间起卦',
          keepAlive: true,
      },
      component: () => import('@/views/divination/timeDivination.vue')
  },
  {
    path: 'divination/divinationDetail',
    name: 'divinationDetail',
    meta: {
        title: '起卦详情',
    },
    component: () => import('@/views/divination/divinationDetail.vue')
  },
  {
    path: 'divination/numberDivination',
    name: 'numberDivination',
    meta: {
        title: '报数起卦',
        keepAlive: true,
    },
    component: () => import('@/views/divination/numberDivination.vue')
  },
  {
    path: 'divination/divinationHistory',
    name: 'divinationHistory',
    meta: {
        title: '历史记录',
    },
    component: () => import('@/views/divination/divinationHistory.vue')
  },
]
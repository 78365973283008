/*奖金池
i:get || post
h:requestGet ||requestPost
*/
import axios from '../axios'
export default {
    // 空间排盘信息接口
    getDetailApi: () => {
        return axios.get('/products/paipan/kongjian');
    },
}